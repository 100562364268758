.header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 0.75rem;
  align-items: center;
  position: relative;
}

.menu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.logo {
  display: flex;
  font-size: 2.25rem;
  color: var(--light);
  font-weight: 800;
}

.menu-icon {
  font-size: 2.125rem;
  cursor: pointer;
  transition: all 0.2s;
}
.menu-icon:hover,
.menu-icon:focus {
  font-size: 2.375rem;
}

.mobile-menu {
  display: none;
}
.web-menu {
  display: flex;
}

@media (max-width: 800px) {
  .logo {
    font-size: 1.5rem;
  }
}
