.about-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-info {
  font-size: 1.25rem;
}

.info-name {
  color: var(--light);
  font-size: 2rem;
  font-weight: bold;
}

.info-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.about-illustration {
  position: relative;
  height: 25rem;
  width: 26.25rem;
}

.illustration {
  position: absolute;
  height: 26.25rem;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.illustration.spot {
  top: -3.125rem;
  animation-duration: 6s;
  animation-name: spot-animation;
}

.illustration.code {
  animation-duration: 7s;
  animation-name: code-animation;
}

.illustration.boxes {
  animation-duration: 8s;
  animation-name: boxes-animation;
}

.illustration.character {
  animation-name: character-animation;
  animation-timing-function: ease-in-out;
}

.illustration.numbers {
  transform: translate(-6.375rem, 1.25rem);
}

.illustration.bubble {
  transform-origin: center;
  animation: bubble-animation 5s infinite;
}

@media (max-width: 720px) {
  .about-top {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .about-info {
    font-size: 1rem;
  }
  
  .info-name {
    font-size: 1.625rem;
  }

  .info-title {
    font-size: 1.25rem;
  }

  .about-illustration {
    align-self: center;
    height: 18rem;
  }

  .illustration {
    height: 20rem;
  }
}


@keyframes spot-animation {
  0%   { transform: translateY(2px) translateX(5px); }
  50%  { transform: translateY(-5px) translateX(0); }
  100% { transform: translateY(2px) translateX(5px); }
}

@keyframes code-animation {
  0%   { transform: translateY(2px) translateX(0); }
  50%  { transform: translateY(-5px) translateX(5px); }
  100% { transform: translateY(2px) translateX(0px); }
}

@keyframes boxes-animation {
  0%   { transform: translateY(1px) translateX(0); }
  50%  { transform: translateY(-3px) translateX(-3px); }
  100% { transform: translateY(1px) translateX(0); }
}

@keyframes bubble-animation {
  0%   { transform: scale(0.95) }
  50%   { transform: scale(1) }
  100%   { transform: scale(0.95) }
}