.nav {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--background);
  padding-top: 1.125rem;
  z-index: 11;
}

.navlink {
  padding: 0 0.3125rem;
}

.nav-option {
  display: block;
  float: left;
  font-size: 1.25rem;
  margin-right: 1rem;
  transition: transform 0.2s;
}
.nav-option:hover,
.nav-option:focus-within {
  transform: scale(1.2);
}
.nav-option:last-child {
  margin-right: 0;
}
.nav-option a {
  display: flex;
  align-items: center;
}

.option-icon {
  display: flex;
  align-items: center;
  margin-right: 0.1875rem;
}

@media (max-width: 720px) {
  .nav-option {
    font-size: 1rem;
  }

  .navlink {
    padding: 0 0.125rem;
  }

  .nav-option {
    margin-right: 0.5rem;
  }
}
