.work-card {
  padding: 0.5rem;
  padding-left: 0;
  width: 100%;
  display: flex;
}

.work-logo-wrapper {
  height: 100%;
  width: max-content;
  margin-right: 0.75rem;
  border-radius: 0.25rem;
}

.work-logo {
  display: block;
  height: 4.6875rem;
  border-radius: 0.25rem;
  transition: transform 0.1s ease-in;
}
.work-logo-wrapper:hover .work-logo,
.work-logo-wrapper:focus .work-logo {
  transform: scale(1.1);
  box-shadow: 0.125rem 0.125rem var(--light);
}

.work-role {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-top: -0.3125rem;
  transition: all 0.1s ease-in;
}

.company-name {
  font-size: 1.375rem;
  font-style: italic;
  margin: 0;
}

.work-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.work-dates {
  color: var(--light-grey);
  margin: 0.3125rem 0;
}

.work-description {
  text-align: left;
  max-width: 100%;
  margin-top: 0.3125rem;
  transition: all 0.1s ease-in;
}

@media (max-width: 720px) {
  .work-card {
    flex-direction: column;
  }

  .work-role {
    font-size: 1.25rem;
  }

  .company-name {
    font-size: 1.125rem;
  }

  .work-logo-wrapper {
    margin-bottom: 0.625rem;
  }

  .work-description {
    font-size: 0.875rem;
  }
}

.button-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right top,
    #026897,
    #027bb2,
    #0098c8,
    #00aacc,
    #00b4d6,
    #19ecdd
  );
  color: white !important;
  width: max-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  margin-right: 0.5rem;
  transition: transform 0.1s ease-in-out, box-shadow 0.2s ease-in-out;
  transform-origin: top left;
}
.button-link:hover,
.button-link:focus {
  background-image: linear-gradient(#191d46, #191d46);
  box-shadow: 0.125rem 0.125rem var(--light);
  transform: scale(1.1);
}

.button-link img {
  height: 1rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
}
