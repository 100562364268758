.skills {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skills-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.75rem 0;
}

.skills-section-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 0.25rem;
}

.light-separator {
  background-color: #f5f5f5;
  height: 0.1875rem;
  width: 100%;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

@media (max-width: 720px) {
  .skills-section-title {
    font-size: 1.125rem;
  }
}
