.skill-card-container {
  width: max-content;
  height: max-content;
  margin-top: 0.125rem;
}

.skill-card {
  box-sizing: border-box;
  width: max-content;
  padding: 1.5rem 1rem 1.5rem 1rem;
  height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.1875rem;
  border: 0.1875rem solid var(--background);
  transition: transform 0.4s, box-shadow 0.3s, border 0.3s;
}

.skill-card-container:hover .skill-card,
.skill-card:focus {
  transform: scale(1.1);
  border: 0.1875rem solid var(--dark);
  box-shadow: 0.1875rem 0.1875rem var(--light);
  background-color: var(--background);
}

.skill-card:hover .skill-name,
.skill-card:focus .skill-name {
  font-weight: bold;
  color: var(--dark);
}

.skill-icon {
  font-size: 4.375rem;
  transition: transform 0.3s;
}
.skill-image {
  height: 3.75rem;
  margin-bottom: 0.25rem;
}

.skill-name {
  font-size: 1.125rem;
  padding-bottom: 0.375rem;
  cursor: pointer;
}

@media (max-width: 720px) {
  .skill-card {
    height: 6.5625rem;
  }

  .skill-icon {
    font-size: 3.375rem;
  }

  .skill-name {
    font-size: 1rem;
  }
}
