.social-contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-wrapper {
  padding: 0.375rem;
  margin: 0.375rem;
}

.social-icon {
  height: 4.375rem;
  transition: transform 0.1s ease-in;
}

.social-contact-link:hover .social-icon,
.social-contact-link:focus .social-icon {
  transform: scale(1.15) translateY(-0.3125rem);
  filter: invert(78%) sepia(39%) saturate(5125%) hue-rotate(131deg)
    brightness(92%) contrast(101%);
}
