/* 
Theme 
  light: #0fb1c6
  dark: #191d46
  accent: #19e4d9
design reference: https://www.youtube.com/watch?v=j0yZc2yfa7o&ab_channel=Overreacted
*/

:root {
  --light: darkturquoise;
  --dark: #191d46;
  --accent: #19e4d9;
  --background: #fffefb;
  --light-grey: #969696;
  --semi-transparent-light-grey: rgba(25, 29, 70, 0.2);

}

* {
  color: var(--dark);  
}

a {
  text-decoration: none;
  color: var(--dark) !important;  
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(----background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
}

@media (max-width: 720px) {
  .section-title {
    font-size: 1.5rem;
  }
}
