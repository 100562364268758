.certificates {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
}

.icon-fix-left-margin {
  margin-left: -0.375rem;
}
